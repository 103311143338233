
import { DndContext, PointerSensor, useDraggable, useSensor } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges, } from '@dnd-kit/modifiers';
import { Coordinates, CSS } from '@dnd-kit/utilities';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IconButton, styled } from "@mui/material";
import { CallProviderName } from '@src/api/organization/hook';
import { CallCtx } from "@src/components/call/call.ctx";
import { companies, leads } from '@src/utils/spa.url';
import { useContext, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function CallDrawerBtn() {
  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  const [position, setPosition] = useState<Coordinates>({ x: 25, y: 20 })
  const { callProvider } = useContext(CallCtx)
  const { pathname } = useLocation()

  const shouldShow = useMemo(() => {
    const isLeadPage = pathname.includes(leads.base) && !pathname.includes(leads.leadSequencePath)
    const isCompanyPage = pathname.includes(companies.base)
    return isLeadPage || isCompanyPage
  }, [pathname])


  if (callProvider === CallProviderName.None || !shouldShow) return null

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      sensors={[pointerSensor]}
      onDragEnd={({ delta }) => {
        setPosition(({ x, y }) => {
          return {
            x: x + delta.x,
            y: y + delta.y,
          };
        });
      }}
    >
      <Btn position={position} />
    </DndContext>
  )
}

function Btn({ position }: { position: Coordinates }) {
  const { setIsCallDrawerOpen } = useContext(CallCtx)
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: 'call-drawer-btn' })
  return (
    <StyledIconButton
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      onClick={() => setIsCallDrawerOpen(true)}
      size="large"
      color='btnAction'
      sx={{
        transform: CSS.Transform.toString(transform),
        top: position.y,
        right: position.x,
      }}
    >
      <PhoneOutlinedIcon />
    </StyledIconButton>
  )
}
