export const enum StaleTime {
  ONE_MINUTE = 1 * 60 * 1000,
  TWO_MINUTES = 2 * 60 * 1000,
  FIVE_MINUTES = 5 * 60 * 1000,
  TEN_MINUTES = 10 * 60 * 1000,
  FIFTEEN_MINUTES = 15 * 60 * 1000,
  THIRTY_MINUTES = 30 * 60 * 1000,
}

export const enum PageSizes {
  Size25 = 25,
  Size50 = 50,
  Size100 = 100,
}

export const pageSizes = [
  PageSizes.Size25,
  PageSizes.Size50,
  PageSizes.Size100,
]

export const enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const enum PollingInterval {
  ONE_SECOND = 1 * 1000,
  FIVE_SECONDS = 5 * 1000,
  TEN_SECONDS = 10 * 1000,
  THIRTY_SECONDS = 30 * 1000,
  ONE_MINUTE = 60 * 1000,
  FIVE_MINUTES = 5 * 60 * 1000,
}
