import { Grid } from '@mui/material';
import * as Sentry from '@sentry/react';
import ErrorEmptyState from '@src/components/ErrorEmptyState';
import PageSpinner from '@src/components/PageSpinner';
import { lightTheme } from '@src/theme';
import React from 'react';


interface IProps {
  children?: React.ReactNode;
}

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";
type PageHasBeenForceRefreshed = {
  hasBeenForceRefreshed: boolean;
  timestamp: number;
};

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || JSON.stringify({ hasBeenForceRefreshed: false, timestamp: 0 })
  ) as PageHasBeenForceRefreshed;

  if (!pageHasAlreadyBeenForceRefreshed.hasBeenForceRefreshed || (Date.now() - pageHasAlreadyBeenForceRefreshed.timestamp) > 10000) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, JSON.stringify({ hasBeenForceRefreshed: true, timestamp: Date.now() }));
    return window.location.reload();
  }
  else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, JSON.stringify({ hasBeenForceRefreshed: false, timestamp: 0 }));
  }
};

export default function ErrorBoundary({ children }: IProps) {
  return (
    <Sentry.ErrorBoundary
      showDialog
      fallback={({ error }) => {
        // handle chunk load errors if that us the case
        if ((error as Error)?.message.includes('dynamically imported module') || (error as Error)?.message.includes('chunk') || (error as Error)?.message.includes('module script failed')) {
          retryPageLoading()
          return <PageSpinner />;
        }

        return (
          <Grid container height='100vh' position='relative' sx={{ backgroundColor: lightTheme.palette.background.default }}>
            <Grid item flex={1} p={4} height='100vh' overflow='auto'>
              <ErrorEmptyState handleOnClick={() => { }} />
            </Grid>
          </Grid>
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

