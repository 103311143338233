import { Stack } from "@mui/material";
import { CallCtx } from "@src/components/call/call.ctx";
import { CommonDrawer } from "@src/components/CommonDrawer";
import { useContext, useEffect, useRef, useState } from "react";

const DIALPAD_CTI_URL = 'https://dialpad.com/apps/p4M3eMyYG579fyRvbMH4rCjxk'

export default function DialPadDrawer() {
  const [loggedInUser, setLoggedInUser] = useState<unknown | null>(null);
  const ref = useRef<HTMLIFrameElement>(null)
  const { isCallDrawerOpen, setIsCallDrawerOpen, resetPhoneQueue, phoneQueue } = useContext(CallCtx)

  const handleClose = () => {
    setIsCallDrawerOpen(false);
    resetPhoneQueue();
  };


  const handleCall = (number: string) => {
    ref.current?.contentWindow?.postMessage({
      'api': 'opencti_dialpad',
      'version': '1.0',
      'method': 'initiate_call',
      'payload': {
        'phone_number': number,
        // 'identity_type': 'CallCenter',
        // 'identity_id': 1234567,
        'custom_data': 'any string',
        // 'outbound_caller_id': '+18005550970',
      }
    }, 'https://dialpad.com')
  }

  useEffect(() => {
    if (!loggedInUser) return;
    // check if drawer is open and phone queue is not empty
    if (isCallDrawerOpen && phoneQueue.length > 0 && loggedInUser) {
      handleCall(phoneQueue[0])
      resetPhoneQueue();
    }
  }, [isCallDrawerOpen, loggedInUser, phoneQueue, resetPhoneQueue])

  // Inside your component
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.api === 'opencti_dialpad' && event.data.method === 'user_authentication' && event.data.payload.user_authenticated) {
        setLoggedInUser(event.data.payload)
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount


  return (
    <CommonDrawer title='Dialpad' open={isCallDrawerOpen} onClose={handleClose} keepMounted SlideProps={{ unmountOnExit: false }}>
      <Stack height="100%" gap={2}>
        <iframe
          ref={ref}
          src={DIALPAD_CTI_URL}
          title="Dialpad"
          allow="microphone; speaker-selection; autoplay; camera; display-capture; hid"
          sandbox="allow-popups allow-scripts allow-same-origin"
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      </Stack>
    </CommonDrawer>
  )
}
