import http from "@src/api/http";
import { StaleTime } from "@src/api/utils";
import { AuthContext, TUser } from "@src/components/auth/auth.ctx";
import { API_BASE_URL } from "@src/utils/env.values";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";

const organizationBaseUrl = `${API_BASE_URL}/organization`;


export const enum CreditUsageServiceProvider {
  APOLLO = 'apollo',
  MILLION_VERIFIER = 'million_verifier',
  OPEN_AI = 'openai',
  OPEN_AI_CHAT_GPT_4O = 'openai-chatgpt-4o',
}

export const creditUsageServiceProviderLabels: Record<CreditUsageServiceProvider, string> = {
  [CreditUsageServiceProvider.APOLLO]: 'Apollo',
  [CreditUsageServiceProvider.MILLION_VERIFIER]: 'Million Verifier',
  [CreditUsageServiceProvider.OPEN_AI]: 'OpenAI',
  [CreditUsageServiceProvider.OPEN_AI_CHAT_GPT_4O]: 'OpenAI',
}

type CreditUsage = {
  service_provider: CreditUsageServiceProvider
  number_of_operations: number
  total_usage: number
}

type CreditUsageResponse = CreditUsage[]

const getCreditUsageQueryKey = (user: TUser | null) => ["creditUsage", user?.user_id, user?.organization_id]

export const useGetCreditUsage = () => {
  const { user } = useContext(AuthContext)
  return useQuery<CreditUsageResponse, Error>({
    queryKey: getCreditUsageQueryKey(user),
    queryFn: () => http.get(`${organizationBaseUrl}/credit_usage`),
    enabled: !!user
  })
}

export const enum CallProviderName {
  None = 'none',
  Dialpad = 'dialpad',
  AirCall = 'aircall',
  CloudTalk = 'cloudtalk',
}

export type CallProvider = {
  name: CallProviderName
  settings: Record<string, unknown> | null
}

type OrganizationSettings = {
  deal_size: number
  call_provider: CallProvider
}

type OrganizationSettingsResponse = {
  settings: OrganizationSettings
  message: string
}

const defaultSettings: OrganizationSettings = {
  deal_size: 1000,
  call_provider: {
    name: CallProviderName.None,
    settings: null
  }
}


const getOrganizationSettingsQueryKey = (userId: string, organizationId: string) => ["organizationSettings", userId, organizationId].filter(Boolean)

export const useGetOrganizationSettings = () => {
  const { user } = useContext(AuthContext)
  return useQuery<OrganizationSettingsResponse, Error>({
    queryKey: getOrganizationSettingsQueryKey(user?.user_id ?? '', user?.organization_id ?? ''),
    queryFn: () => http.get(`${organizationBaseUrl}/${user?.organization_id}/settings`),
    enabled: !!user && !!user.organization_id,
    select: (data) => {
      // Validate the data and if any key is missing, add it with default value
      data.settings = { ...defaultSettings, ...data.settings };
      return data
    },
    staleTime: StaleTime.FIVE_MINUTES
  })
}


type UpdateOrganizationSettingsRequest = {
  deal_size?: number
  call_provider?: CallProvider
}

type UpdateOrganizationSettingsResponse = {
  message: string
}

export const useUpdateOrganizationSettingsMutation = () => {
  const { user } = useContext(AuthContext)
  const queryClient = useQueryClient()
  return useMutation<UpdateOrganizationSettingsResponse, Error, UpdateOrganizationSettingsRequest>({
    mutationFn: (data) => http.patch(`${organizationBaseUrl}/${user?.organization_id}/settings`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getOrganizationSettingsQueryKey(user?.user_id ?? '', user?.organization_id ?? '') })
    }
  })
}
