import { CallProviderName, useGetOrganizationSettings } from "@src/api/organization/hook";
import CallDrawerBtn from "@src/components/call/CallDrawerBtn";
import { createContext, useCallback, useEffect, useState } from "react";

export type TCallCtx = {
  isCallDrawerOpen: boolean;
  setIsCallDrawerOpen: (open: boolean) => void;
  phoneQueue: string[];
  enqueuePhoneNumber: (phoneNumber: string) => void;
  dequeuePhoneNumber: () => string | undefined;
  resetPhoneQueue: () => void;
  callProvider: CallProviderName
}

const defaultValue: TCallCtx = {
  isCallDrawerOpen: false,
  setIsCallDrawerOpen: () => { },
  phoneQueue: [],
  enqueuePhoneNumber: () => { },
  dequeuePhoneNumber: () => undefined,
  resetPhoneQueue: () => { },
  callProvider: CallProviderName.None,
}

export const CallCtx = createContext<TCallCtx>(defaultValue);

export function CallCtxProvider({ children }: { children: React.ReactNode }) {
  const [isCallDrawerOpen, setIsCallDrawerOpen] = useState(defaultValue.isCallDrawerOpen);
  const [phoneQueue, setPhoneQueue] = useState<string[]>(defaultValue.phoneQueue);
  const [callProvider, setCallProvider] = useState<CallProviderName>(defaultValue.callProvider)

  const { data: organizationSettings, isLoading } = useGetOrganizationSettings()

  useEffect(() => {
    if (!isLoading && organizationSettings?.settings.call_provider) {
      setCallProvider(organizationSettings?.settings.call_provider.name)
    }
  }, [isLoading, organizationSettings])

  // Open the drawer when the queue is not empty
  useEffect(() => {
    if (phoneQueue.length > 0 && !isCallDrawerOpen) {
      setIsCallDrawerOpen(true);
    }
  }, [phoneQueue, isCallDrawerOpen]);

  const enqueuePhoneNumber = useCallback((phoneNumber: string) => {
    setPhoneQueue((prev) => [...prev, phoneNumber]);
  }, [setPhoneQueue]);

  const dequeuePhoneNumber = useCallback(() => {
    return phoneQueue.shift();
  }, [phoneQueue]);

  const resetPhoneQueue = useCallback(() => {
    setPhoneQueue([]);
  }, [setPhoneQueue]);


  return (
    <CallCtx.Provider value={{
      isCallDrawerOpen,
      setIsCallDrawerOpen,
      phoneQueue,
      enqueuePhoneNumber,
      dequeuePhoneNumber,
      resetPhoneQueue,
      callProvider
    }}>
      {children}
      <CallDrawerBtn />
    </CallCtx.Provider>
  );
}


