import { CallProviderName } from "@src/api/organization/hook"
import AirCallDrawer from "@src/components/call/AircallDrawer"
import { CallCtx } from "@src/components/call/call.ctx"
import CloudTalkDrawer from "@src/components/call/CloudTalkDrawer"
import DialPadDrawer from "@src/components/call/DialPadDrawer"
import { useContext } from "react"

export default function CallDrawer() {
  const { callProvider } = useContext(CallCtx)


  if (callProvider === CallProviderName.Dialpad) return <DialPadDrawer />
  if (callProvider === CallProviderName.AirCall) return <AirCallDrawer />
  if (callProvider === CallProviderName.CloudTalk) return <CloudTalkDrawer />
  return null
}
