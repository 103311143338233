import { CssBaseline, ThemeProvider } from '@mui/material';
import AppAlerts from '@src/components/AppAlerts';
import ErrorBoundary from '@src/components/ErrorBoundary';
import { AuthProvider } from '@src/components/auth/AuthProvider';
import { lightTheme } from '@src/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import CallDrawer from '@src/components/call/CallDrawer';
import { CallCtxProvider } from '@src/components/call/call.ctx';


const queryClient = new QueryClient();

function App() {

  return (
    <BrowserRouter>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline enableColorScheme />
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <AppAlerts>
              <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CallCtxProvider>
                    <Router />
                    <CallDrawer />
                  </CallCtxProvider>
                </LocalizationProvider>
              </AuthProvider>
            </AppAlerts>
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
