import { Grid, styled } from "@mui/material";
import { CallCtx } from "@src/components/call/call.ctx";
import { CommonDrawer } from "@src/components/CommonDrawer";
import AircallPhone, { AircallUser, LoggedInUser } from 'aircall-everywhere';
import { useCallback, useContext, useEffect, useState } from "react";

const GridWithNoIframeBorder = styled(Grid)(({ theme }) => ({
  [`& iframe`]: {
    border: 'none !important',
  }
}))

const AirCallPhone = AircallPhone

export default function AirCallDrawer() {
  const { isCallDrawerOpen, setIsCallDrawerOpen, resetPhoneQueue, phoneQueue } = useContext(CallCtx)
  const [phoneInstance, setPhoneInstance] = useState<any>(null);
  const [loggedInUser, setLoggedInUser] = useState<AircallUser | null>(null);

  const initRef = useCallback((node: HTMLDivElement) => {
    if (node && !phoneInstance) {
      const instance = new AirCallPhone({
        onLogin: (settings: LoggedInUser) => {
          setLoggedInUser(settings.user);
        },
        onLogout: () => {
          setLoggedInUser(null);
        },
        domToLoadPhone: '#aircall-phone',
        size: 'auto'
      });
      setPhoneInstance(instance)
    }
  }, [phoneInstance])

  const handleClose = () => {
    setIsCallDrawerOpen(false);
    resetPhoneQueue();
  };

  useEffect(() => {
    if (!loggedInUser) return;
    // check if drawer is open and phone queue is not empty and phone instance is not null
    if (isCallDrawerOpen && phoneQueue.length > 0 && phoneInstance) {
      phoneInstance.send('dial_number', { phone_number: phoneQueue[0] }, (success: boolean) => {
        if (success) {
          resetPhoneQueue();
        }
      });
    }
  }, [isCallDrawerOpen, loggedInUser, phoneInstance, phoneQueue, resetPhoneQueue])


  return (
    <CommonDrawer title='Aircall' open={isCallDrawerOpen} onClose={handleClose} keepMounted SlideProps={{ unmountOnExit: false }}>
      <Grid container height='100%'>
        <GridWithNoIframeBorder item xs={12} id="aircall-phone" ref={initRef}>

        </GridWithNoIframeBorder>
      </Grid>
    </CommonDrawer>
  );
}
