import CloseIcon from '@mui/icons-material/Close';
import { ButtonProps, Divider, Drawer, DrawerProps, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { ReactNode } from 'react';


export function CommonDrawer({ anchor = 'right', title, icon, showDivider = true, ...props }: DrawerProps & { title: ReactNode, icon?: ButtonProps['startIcon'], showDivider?: boolean }) {
  const theme = useTheme();

  return (
    <Drawer anchor={anchor}
      PaperProps={{
        sx: {
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.default,
          width: {
            xs: '100%',
            sm: '70%',
            md: '50%',
            lg: '35%',
            xl: '30%'
          },
        }
      }}
      {...props}
    >
      <Grid container alignItems='center'>
        <Grid item flex={1}>
          <Typography variant="h6" display='flex' alignItems='center' gap={1} fontWeight={theme.typography.fontWeightRegular}>{icon}{title}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={(e) => props.onClose?.(e, 'backdropClick')} size='small'>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {showDivider && <Divider sx={{ marginY: theme.spacing(2) }} />}
      {props.children}
    </Drawer>
  )
}
