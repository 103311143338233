import { Stack } from "@mui/material";
import { CallCtx } from "@src/components/call/call.ctx";
import { CommonDrawer } from "@src/components/CommonDrawer";
import { useContext, useRef } from "react";

export default function CloudTalkDrawer() {
  const ref = useRef<HTMLIFrameElement>(null)
  const { isCallDrawerOpen, setIsCallDrawerOpen, resetPhoneQueue } = useContext(CallCtx)


  const handleClose = () => {
    setIsCallDrawerOpen(false);
    resetPhoneQueue();
  };


  return (
    <CommonDrawer title='CloudTalk' open={isCallDrawerOpen} onClose={handleClose} keepMounted SlideProps={{ unmountOnExit: false }}>
      <Stack height="100%" gap={2}>
        <iframe
          ref={ref}
          src="https://phone.cloudtalk.io/?partner=revamp"
          title="CloudTalk"
          allow="microphone *"
          sandbox="allow-popups allow-scripts allow-same-origin allow-forms allow-clipboard"
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      </Stack>
    </CommonDrawer>
  )
}
